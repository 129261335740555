



































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

@Component
export default class ActividadesComponent extends Mixins(FormValidator) {
  private actividades: Array<any> = [];
  private mostrar: any = null;

  private mounted() {
    this.getActividades();
  }

  private getActividades() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("/actividades")
      .then((res: any) => {
        this.actividades = res.data;
        this.mostrar = true;
        for (let i = 0; i < this.actividades.length; i++) {
          if (this.actividades[i].foto != null) {
            this.actividades[i].foto =
              " https://d1rofaen6s22p4.cloudfront.net/" +
              this.actividades[i].foto;
          } else {
            this.actividades[
              i
            ].foto = require("@/assets/logos/logo-default.png");
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        console.log(err);
        this.mostrar = false;
        this.$q.loading.hide();
      });
  }

  private irActividad(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "PublicacionAct" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "PublicacionAct",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }
}








import { Vue, Component, Prop } from "vue-property-decorator";
import Actividades from "@/components/Actividades/ActividadesComponent.vue";

@Component({
  components: {
    Actividades
  }
})
export default class ActividadesView extends Vue {
  private mounted() {}
}
